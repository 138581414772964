import React, { useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client';
import LazyLoad from 'react-lazyload';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import axios from "axios";
const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT

function ParcMachines() {
  const token = localStorage.getItem('jwt');
  const [defaultMachinesData, setDefaultMachinesData] = useState([]);
  const [machineDataa, setMachineDataa] = useState([]);
  const Machines = useRef(JSON.parse(localStorage.getItem('machines') || '[]'));

  const _arrayBufferToBase64 = (buffer) => {
    const CHUNK_SIZE = 0x8000; // process in chunks to avoid call stack exceeded error in `String.fromCharCode`
    let result = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i += CHUNK_SIZE) {
      const chunk = bytes.subarray(i, i + CHUNK_SIZE);
      result += String.fromCharCode.apply(null, chunk);
    }
    return window.btoa(result);
  };

  const initializeMachineData = () => Machines.current.map(element => ({
    machine_id: element.machine_id,
    machine_name: element.machine_name,
    PTotal: 0,
    PApparante: 0,
    PMoyenne: 0,
    PFMoyenne: 0,
    VL1: 0,
    VL2: 0,
    VL3: 0,
    AL1: 0,
    AL2: 0,
    AL3: 0,
    etat: -1,
  }));

  useEffect(() => {
    setDefaultMachinesData(initializeMachineData());
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/machines/getParamsMachines`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setMachineDataa(response.data);
      } catch (error) {
        console.error("Failed to fetch machine data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    const updateMachineData = (data) => {
      let moy_cosphi = data.cosphi ? (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3 : 0;
      let etat = moy_cosphi < 0.3 && moy_cosphi > 0 ? 0 : moy_cosphi >= 0.3 && moy_cosphi <= 1 ? 1 : -1;

      setDefaultMachinesData(prevData => prevData.map(machine => {
        if (machine.machine_id === data.machine.id_machine) {
          return {
            ...machine,
            etat: etat,
            PTotal: +data.power.power.toFixed(2),
            PApparante: +(data.powerA.powerA / 3).toFixed(2),
            PMoyenne: +(data.power.power / 3).toFixed(2),
            PFMoyenne: +moy_cosphi.toFixed(2),
            VL1: +data.voltage.voltage1.toFixed(2),
            VL2: +data.voltage.voltage2.toFixed(2),
            VL3: +data.voltage.voltage3.toFixed(2),
            AL1: +data.current.current1.toFixed(2),
            AL2: +data.current.current2.toFixed(2),
            AL3: +data.current.current3.toFixed(2)
          };
        }
        return machine;
      }));
    };

    socket.on("data", updateMachineData);

    return () => {
      socket.off("data", updateMachineData);
      socket.disconnect();
    };
  }, []);


  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="parcMachine" />
          <div className="app-content main-content mt-20">
            <div className="side-app">

              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Parc Machines</h1>
                  </div>
                </div>

                <div className="wrapper">
                  {defaultMachinesData && Object.keys(defaultMachinesData).map(key => {
                    // Find the corresponding machine data entry by machine_id
                    const machine = machineDataa.find(md => md.id_machine === defaultMachinesData[key].machine_id);
                    
                    return (
                      <div className="card-n" key={key}>
                        <LazyLoad height={200} once>
                          {machine && machine.img && machine.img.data && machine.img.data.data ?
                            <img
                              src={`data:${machine.img.contentType};base64,${_arrayBufferToBase64(machine.img.data.data)}`}
                              alt='Machine'
                            />
                            : <img alt="" />
                          }
                        </LazyLoad>
                        <div className='card-name'>
                          <div className='machine-name-container'><h3> <b>{defaultMachinesData[key].machine_name}</b></h3></div>
                        </div>
                        <div className='card-status'>
                          {(defaultMachinesData[key]['etat'] === -1) ?
                            <div className="wrem-2 h-6 bg-grey br-100"></div> :
                            ((defaultMachinesData[key]['etat'] === 1) ?
                              <div className="wrem-2 h-6 bg-green br-100"></div> :
                              <div className="wrem-2 h-6 bg-danger br-100"></div>)
                          }
                        </div>

                        <div className="info">
                          <p>P. Totale : {defaultMachinesData[key].PTotal} W</p>
                          <p>Courant L1 : {defaultMachinesData[key].AL1} A </p>
                          <p>Courant L2 : {defaultMachinesData[key].AL2} A </p>
                          <p>Courant L3 : {defaultMachinesData[key].AL3} A </p>
                          <p>________________________________</p>
                          <p>P. Apparente : {defaultMachinesData[key].PApparante} VA </p>
                          <p>V1 : {defaultMachinesData[key].VL1} V </p>
                          <p>V2 : {defaultMachinesData[key].VL2} V </p>
                          <p>V3 : {defaultMachinesData[key].VL3} V </p>
                          <p>________________________________</p>
                          <p>P. Moyenne : {defaultMachinesData[key].PMoyenne} W </p>
                          <p>PF. Moyenne (cosphi) : {defaultMachinesData[key].PFMoyenne} </p>
                        </div>
                      </div>
                    );
                  })}
                </div>

              </div>
            </div>

          </div>
        </div>
        <Footer />
      </div>
    </>

  );
}

export default ParcMachines
